<template>
    <div>
        <Navbar/>
        <b-container class="mt-5">
            <h3 v-if="title" class="txt_secondary text-uppercase mb-3">Registro de especialidades</h3>
            <h3 v-if="title2" class="txt_secondary text-uppercase mb-3">Editar especialidad</h3>
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <b-row>
                        <b-col cols="12" sm="12" md="6" lg="6">
                            <b-table-simple class="table-sm">
                                <b-tbody>  
                                    <b-tr>
                                        <b-th class="th_style">Nombre</b-th>
                                        <b-td class="border_table">
                                            <b-form-input :disabled="disabled" autocomplete="off" v-model="especialidad.nombre" :state="nombre" class="border-0 disabled_inputs" size="sm" placeholder="ingrese nombre de la especialidad"/>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <div v-if="error" class="contenedor-de-errores pt-3 pb-1 mb-2">
                                <ul class="lista-errores px-2">
                                    <li v-for="(err,index) of errores" :key="index"><i class="fa fa-warning px-1 icon-errror" v-if="!err.id" />| {{err[0]}} <br>
                                    </li>
                                </ul>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="12" md="4" lg="2">
                            <div class="d-flex mb-4">
                                <span class="fa fa-save fa-lg my-auto px-3 icon_color"></span>
                                <button class="btn_formluario btn-block py-2 txt_white text-uppercase" v-if="btnGuardar" @click="addEspecialidad()">Guardar</button>
                                <button class="btn_formluario btn-block py-2 txt_white text-uppercase" v-if="btnEditar" @click="Editar()">Editar</button>
                                <button class="btn_formluario btn-block py-2 txt_white text-uppercase" v-if="btnActualizar" @click="editEspecialidad()">Actualizar</button>
                            </div>
                        </b-col>
                        <b-col v-if="btnEliminar" cols="12" sm="12" md="4" lg="2">
                            <div class="d-flex mb-4">
                                <span class="fa fa-trash fa-lg my-auto px-3 icon_color icon_color_delete"></span>
                                <button class="btn_radius btn_delete btn-block py-2 txt_white text-uppercase" @click="abrirModalEliminar()">Eliminar</button>
                            </div>
                        </b-col>
                        <b-col v-if="btnAtras" cols="12" sm="12" md="4" lg="2">
                            <div class="d-flex mb-4">
                                <span class="fa fa-plus fa-lg my-auto px-3 txt_secondary"></span>
                                <button class="btn_radius border_secoundary border-third btn-block py-2 txt_white text-uppercase" @click="Atras()">Registrar</button>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="mt-5">
                <b-col cols="12" sm="12" md="12" lg="12">
                    <b-row>
                        <b-col cols="12" sm="12" md="12" lg="12">
                            <b-skeleton-wrapper class="p-0 m-0" :loading="loading">
                                <template #loading>
                                    <b-row align-h="center" class="g-3">
                                        <b-col v-for="item in skeleton" :key="item.numero" class="mb-4" cols="12" sm="12" md="4" lg="4">
                                            <b-skeleton class="size btn-block border-0" type="input"></b-skeleton>
                                        </b-col>
                                    </b-row>
                                </template>
                                <b-row>
                                    <b-col v-for="item in allEspecialidad" :key="item.nombre" class="mb-3" cols="12" sm="12" md="4" lg="4">
                                        <div @click="obtenerId(item)" v-bind:title="item.nombre" class="input-group flex-nowrap cursor">
                                            <span class="input-group-text" id="img" align="center">
                                                <i class="fa fa-stethoscope fa-2x"></i>
                                            </span>
                                            <p type="text" class="form-control contenedor py-3 pl-3 bg_third" id="contenido">{{item.nombre}}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-skeleton-wrapper>
                        </b-col>
                    </b-row>
                    <div class="overflow-auto mt-4" v-if="especialidades.length>9">
                        <div>
                            <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows" align="center"></b-pagination>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <!-- Modal -->
    
        <b-modal class="rounded-lg" size="sm" v-model="openDeleteEsp" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer hide-header centered>
            <div class="d-flex justify-content-center">
                <span class="icono text-center p-2" align="center">
                    <i class="fa fa-warning fa-3x"></i>
                </span>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <span><strong>¡Alerta!</strong></span><br>
            </div>
            <div class="justify-content-center text-center mt-1">
                <p class="subttitle-modal">¿Estás seguro de querer eliminar esta especialidad?</p>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <button class="btn-modal-success border-0 px-3 pb-2 pt-1" @click="eliminar()">Eliminar</button>
                <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-4">Cancelar</button>
            </div>
        </b-modal>

        <Success :abrir="openSuccess" @cerrarModal="CerrarModal()"/>
        <DeleteSuccess :abrir="openDeleteSuccess" @cerrarModalDeleteSuccess="cerrarModalDeleteSuccess()"/>
        <EditSuccess :abrir="openEditSuccess"  @cerrarModalEditSuccess="cerrarModalEditSuccess()"/>
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/> 
    </div>    
</template>

<script>
import Vue from 'vue'
import Navbar from "@/components/Navbar.vue"
import Success from "@/components/Modals/Success.vue"
import DeleteSuccess from "@/components/Modals/DeleteSuccess.vue"
import Error from "@/components/Modals/Error.vue";
import EditSuccess from "@/components/Modals/EditSuccess.vue"
import { url } from "../../../../settings"

export default {
    name: 'RegistroEspecialidades',
    components: { Navbar, Success, DeleteSuccess, EditSuccess, Error },

    data:() => ({
        openSuccess: false,
        openDeleteEsp: false,
        openDeleteSuccess: false,
        openEditSuccess: false,

        openError: false,
        error: false,
        errores:"",

        title: true,
        title2: false,

        disabled: false,
        btnGuardar: true,
        btnEliminar: false,
        btnEditar: false,
        btnActualizar: false,
        btnAtras: false,

        skeleton: [1,2,3,4,5,6,7,8,9],
        loading: false,
        currentPage: 1,
        perPage:12,
        especialidades: [],
        especialidad: {
            id:'',
            nombre: ''
        }
    }),

    computed: {
        nombre() {
            return this.especialidad.nombre.length >= 5
        },

        allEspecialidad () {
            const items = this.especialidades;
            // Return just page of items needed
            return items.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            )
        },

        rows() {
            return this.especialidades.length
        },
    },

    methods: {
        cerrarModal() {
            this.openDeleteEsp = false;
        },

        Atras() {
            this.btnEditar = false;
            this.title2 = false;
            this.title = true;
            this.btnEliminar = false;
            this.btnActualizar = false;
            this.btnAtras = false;
            this.btnGuardar = true;
            this.disabled=false;
            this.especialidad.id = ''
            this.especialidad.nombre = ''
        },

        abrirModalEliminar() {
            this.openDeleteEsp = true;
        },

        obtenerId(item) {
            this.especialidad.id = item.id;
            this.especialidad.nombre = item.nombre
            this.title = false;
            this.title2 = true;
            this.btnGuardar = false;
            this.disabled = false;
            this.btnEditar = true;
            this.btnEliminar = true;
            this.btnAtras = true;
            this.btnActualizar = false;
        },

        Editar() {
            this.disabled = false;
            this.btnEditar = false;
            this.btnActualizar = true;
        },

        normalidad() {
            this.btnGuardar = true;
            this.btnActualizar = false;
            this.btnEditar = false;
            this.btnEliminar = false;
            this.btnAtras = false;
            this.especialidad.id = '';
            this.especialidad.nombre = '';
        },

        async eliminar() {
            try {
                const res = await fetch(`${url}/api/especialidades/destroy/${this.especialidad.id}`, {
                    method: 'delete',
                    headers: {
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                this.cerrarModal();
                this.title2 = false;
                this.title = true;
                this.openDeleteSuccess = true;
                this.obtenerEspecialidades();
                this.normalidad();
                this.disabled = false;
            } catch (error) {
                console.log(error);
            }
        },

        async obtenerEspecialidades() {
            this.loading = true;
            try {
                const res = await fetch(`${url}/api/especialidades`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }

                const data = await res.json();
                console.log(data);
                this.especialidades = data.especialidades;
                this.loading = false;

            } catch (error) {
                console.log(error);
            }
        },

        async addEspecialidad() {
            const dtsEsp = {
                nombre: this.especialidad.nombre
            };

            try {
                const res = await fetch(`${url}/api/especialidades/store`, {
                    method: 'post',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(dtsEsp)
                });

                const data = await res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err=[];
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err=val.errors
                    }
                });

                if (warn) {
                    this.obtenerEspecialidades();
                    this.openSuccess = true;
                    this.normalidad();
                    this.error = false;
                    this.errores = "";
                } else {
                    this.errores=err
                    this.openError=true
                    this.error=true
                }

            } catch (error) {
                console.log(error);
            }
        },

        async editEspecialidad() {
            const dtsEsp = {
                nombre: this.especialidad.nombre
            };

            try {
                const res = await fetch(`${url}/api/especialidades/update/${this.especialidad.id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(dtsEsp)
                });

                const data = await res.json();
                console.log(data);
                this.normalidad();
                this.obtenerEspecialidades();
                this.openEditSuccess = true;
                this.title = true;
                this.title2 = false;
            } catch (error) {
                console.log(error);
            }
        },
        CerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            this.openError=false
        },
        cerrarModalDeleteSuccess(){
            this.openDeleteSuccess = false;
        },
        cerrarModalEditSuccess(){
            console.log(`cerrarModalEditSuccess`);
            this.openEditSuccess = false;
        }
    },

    mounted() {
        this.obtenerEspecialidades();
    },
    destroyed() {  
        this.$destroy();
    }


}
</script>

<style scoped>
    /* Modal */
    .fa-warning {
        color: #d15460;
    }

    .icon-errror {
        color: rgb(60, 59, 59);
    }
    /* Skeleton */
    .size {
        height: 70px;
        background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
        background-size: 400% 400%;
        -webkit-animation: skeletonLoading 2.25s ease infinite;
        -moz-animation: skeletonLoading 2.25s ease infinite;
        animation: skeletonLoading 2.25s ease infinite;
    }

    @-webkit-keyframes skeletonLoading {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    @-moz-keyframes skeletonLoading {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    @keyframes skeletonLoading {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    /* end */

    .border_red {
        border: 1px solid #a25c68;
        color: #a25c68;
        background-color: white;
    }
    
    .contenedor {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cursor {
        cursor: pointer;
    }

    #contenido {
        border-radius: 15px;
        height: auto;
        margin-left: 35px;
        justify-content: center;
        text-align: center;
    }

    #img {
        position: absolute;
        border-radius: 50px;
        width: auto;
        height: 3.1rem;
        margin-top: 4px;
        z-index: 1;
        background: white;
        justify-content: center;
        align-items: center;
    }

     p {
        font-size: 0.8rem;
    }

    i {
        color: #ced4da;
    }
</style>